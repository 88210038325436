import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, Link } from 'react-router-dom';
import { Table, TableBody, TableCell, TableHead, TableRow, Paper, TableSortLabel } from '@material-ui/core';
import { GetPeopleDispatch } from '../../reducers/DiseasesReducer'
import { useDispatch, useSelector } from 'react-redux';
import PlayersModal from './PlayersModal';
import BrandModal from './BrandModal';
import VentureCapitalModal from './VentureCapitalModal';
import TrialsModal from './ActiveTrialsModal';
import VentureCapitalCompanyDealsModal from './VentureCapitalCompanyDealsModal';
import PeopleCEOModal from './PeopleCEOModal';
import TranscriptModal from './TranscriptModal';
import './PeopleTable.scss';
import { numberWithCommas, convertToMimillions, splitText, convertToHumanDate, removeDayFromDate, amountToAbbreviateNumber, isDateString, differenceDays, removeDayAndMonthFromDate } from './Helper';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import CompanyDealsModal from './CompanyDealsModal';
import LanguageIcon from '@material-ui/icons/Language';
import AxlChips from './reusableComponents/MUI/AxlChips';
import AxlDot from './reusableComponents/MUI/AxlDot';
import linkedin from '../../assets/img/linkedin.png';
import doximity from '../../assets/img/doximity.png';
import usnews from '../../assets/img/usnews.png';
import website from '../../assets/img/website.png';
import fda_logo from '../../assets/img/FDA-Logo.png';
import Brightness1Icon from '@material-ui/icons/Brightness1';

const GeneralTable = ({financials, options, apiCallRequest}) => {
    const dealsStore = useSelector((state) => state.diseases);
    const { playersDataLevelLeaf } = dealsStore;
    const [university, setUniversity] = useState(
        [
            {'university':'axl_md_university', 'degree':'axl_md_degree'},
            {'university':'axl_phd_university', 'degree':'axl_phd_degree'},
            {'university':'axl_mba_university', 'degree':'axl_mba_degree'},
            {'university':'axl_master_university', 'degree':'axl_master_degree'},
            {'university':'axl_undergrad_university', 'degree':'axl_undergrad_degree'}
        ]
    );

    if(playersDataLevelLeaf['data']){
        options.leafModalData = {
            name:playersDataLevelLeaf['data']['target'],
            nextData:playersDataLevelLeaf['data']
        }
    }

    const [sortField, setSortField] = useState('name');
    const [sortDirection, setSortDirection] = useState('asc');
    const [showModal, setShowModal] = useState(false); // State to toggle modal visibility
 
    const handleSort = (field) => {
        const newDirection = sortField === field && sortDirection === 'asc' ? 'desc' : 'asc';
        setSortField(field);
        setSortDirection(newDirection);
    };
    let filteredFinancials = financials;
    if(options.trialType === 'venture_capital'){
        filteredFinancials = financials.filter(item => {
            if(options.core_team){
                return !(item['axl_rank'] && options.axl_rank && item['axl_rank'] > options.axl_rank);
            } else {
                return !(item['axl_rank'] && options.axl_rank && item['axl_rank'] < options.axl_rank);
            }
        });
    }
    const sortedFinancials = filteredFinancials.length > 0 && filteredFinancials.map(item => {
        const newItem = {};
        for (const key in item) {
            if (item.hasOwnProperty(key) && typeof item[key] === 'string') {
                if(options.trialType === 'venture_capital' ||
                    options.trialType === 'venture_capital_people' ||
                    options.trialType === 'brands' ||
                    options.trialType === 'mocks' ||
                    options.trialType === 'investments' ||
                    options.trialType === 'physicians' ||
                    options.trialType === 'trials' ||
                    options.trialType === 'drug' ||
                    options.trialType === 'phases'){
                    newItem[key] = item[key];
                }else{
                    if(key == 'axl_market_cap'){
                        newItem[key] = Number(item[key]);
                    }else{
                        newItem[key] = item[key].toLowerCase();
                    }
                }
            } else {
                newItem[key] = item[key];
            }
        }
        return newItem;
    }).sort((a, b) => {
        const valueA = a[sortField];
        const valueB = b[sortField];
    
        if (valueA < valueB) {
            return sortDirection === 'asc' ? -1 : 1;
        }
        if (valueA > valueB) {
            return sortDirection === 'asc' ? 1 : -1;
        }
        return 0;
    });
    
    const handleCallRequest = (value) => {
        if(options.disableClick){
            return;
        }
        if(options.trialType === 'deals' ||
            options.trialType === 'players' ||
            options.trialType === 'brands' ||
            options.trialType === 'mocks' ||
            options.trialType === 'venture_capital' ||
            options.trialType === 'investments' ||
            options.trialType === 'venture_capital_people' ||
            options.trialType === 'physicians' ||
            options.trialType === 'trials' ||
            options.trialType === 'phases' ||
            options.trialType == 'device_510k' ||
            options.trialType === 'exits' ||
            options.trialType == 'competitors' ||
            options.trialType === 'drug' ||
            options.trialType === 'mocks_transcript'
        ){
            apiCallRequest(value)
            setShowModal(true);
        }
      }

    let columns_financials = []
    if(options.trialType === 'brands' ||
        options.trialType==='mocks_investments' ||
        options.trialType === 'patents_exclusivity' ||
        options.trialType === 'venture_capital' ||
        options.trialType === 'venture_capital_people' ||
        options.trialType === 'mocks' ||
        options.trialType === 'deals' ||
        options.trialType === 'investments' ||
        options.trialType === 'physicians' ||
        options.trialType === 'trials' ||
        options.trialType === 'phases' ||
        options.trialType === 'pipeline' ||
        options.trialType == 'device_510k' ||
        options.trialType === 'players' ||
        options.trialType === 'exits' ||
        options.trialType === 'competitors' ||
        options.trialType === 'drug' ||
        options.trialType === 'treatments' ||
        options.trialType === 'mocks_transcript'
    ){
        columns_financials = options.columns
    }else{
        columns_financials = financials[0]
    }

    const getMaxTruePhase = json =>
        Object.keys(json).reverse().find(key => json[key]);

    const getColorForPhase = (phase, column) => {
        const phaseOrder = {
            'Phase 1': 1,
            'Phase 2': 2,
            'Phase 3': 3,
            'Approved': 4
        };
    
        const maxPhase = phaseOrder[phase];
        const currentColumnPhase = phaseOrder[column];
    
        // If column is before or equal to the current phase, return the color of the current phase
        if (currentColumnPhase <= maxPhase) {
            switch (phase) {
                case 'Phase 1':
                    return 'bg-phase1';
                case 'Phase 2':
                    return 'bg-phase2';
                case 'Phase 3':
                    return 'bg-phase3';
                case 'Approved':
                    return 'bg-approved';
                default:
                    return '';
            }
        }
    
        return ''; // Return empty string if column is after the phase
    };
    
    

    return (
        financials.length > 0 && <>
        <Table className='people-table'>
            <TableHead>
            <TableRow className='people-header-row'>
            {/* ${typeof columns_financials[column] === 'number'?'text-end':columns_financials[column]} */}
                {Object.keys(columns_financials).map((column)=>{
                    return column !== 'axl_fda_url' && <TableCell className={`people-cell people-body-column text-capitalize`}>
                        <TableSortLabel
                            active={sortField === column}
                            direction={sortDirection}
                            onClick={() => handleSort(column)}
                        >
                            {
                                column==='roa'?
                                'RoA':
                                column==='mdr'?
                                'MDR':
                                column==='wam_price'?
                                'WAM Price':
                                column==='aca_limit'?
                                'ACA Limit':
                                column==='axl_market_cap'?
                                (column.replaceAll('_',' ').replaceAll('axl ','')+"($M)"):
                                columns_financials[column]?
                                columns_financials[column]:
                                (column.replaceAll('_',' ').replaceAll('axl ','').replaceAll('vc','').replaceAll('new',''))
                            }
                        </TableSortLabel>
                    </TableCell>
                    })
                }
            </TableRow>
            </TableHead>
            <TableBody>
            {/* ${typeof financial[column] === 'number' || isDateString(financial[column]) === true ?'text-end':''} */}
            {sortedFinancials.length > 0 && sortedFinancials.map((financial, index) => (
                <TableRow key={index} className={`people-row people-body-row ${options.trialType==='brands' || options.trialType==='mocks_investments' || options.trialType==='venture_capital' ? 'brands-row' : ''}`} onClick={() => handleCallRequest(financial)}>
                    {Object.keys(columns_financials).map((column,index)=>{
                        return column !== 'axl_fda_url' && <TableCell className={`${options.trialType==='mocks'?'':'people-cell'} text-capitalize ${options.trialType==='pipeline' ? 'pipeline-body-column' : 'people-body-column'} ${options.trialType === 'pipeline' ? column.replace(/\s+/g, '_') : ''} ${getColorForPhase(getMaxTruePhase(financial), column)}`}>
                                {
                                    column==='url'? 
                                        <Link
                                            target="_blank"
                                            to={{
                                            pathname: financial[column],
                                            }}>
                                            <i class="fas fa-external-link-alt"></i>
                                        </Link>:
                                    options.trialType === 'patents_exclusivity'?
                                        <span title={splitText(financial[column],';',10)}>{financial[column] ? splitText(financial[column],';',10):'-'}</span>:
                                    
                                    options.trialType === 'investments' && column==='year'?
                                        removeDayFromDate(financial[column]):
                                    options.trialType==='exits' && column==='axl_vc_allinvestors'?
                                    <div className="chips-container">
                                        {
                                            (() => {
                                                let counter = 0;
                                            
                                                // Filter valid entries before processing
                                                const renderedColumns = financial['axl_vc_allinvestors'] &&financial['axl_vc_allinvestors'].split(';').map((sub_column) => {
                                                    counter++;
                                                    if (counter < 3) {
                                                    return (
                                                        <span
                                                        title={sub_column}
                                                        key={sub_column}
                                                        >
                                                        <AxlChips
                                                            options={{
                                                            chips: sub_column,
                                                            size: 'small',
                                                            dynamicPage: '',
                                                            }}
                                                        />
                                                        </span>
                                                    );
                                                    }
                                                    return null;
                                                });
                                            
                                                // Generate the tooltip for "view more"
                                                const viewMoreTitle = financial['axl_vc_allinvestors'] && financial['axl_vc_allinvestors'].split(';').map((sub_column) => {
                                                    return (
                                                        (sub_column ? '> '+sub_column : '')
                                                    );
                                                })
                                                .join('\n'); // Join valid entries with newlines
                                            
                                                return (
                                                <>
                                                    {renderedColumns}
                                                    {counter > 2 && (
                                                    <span title={viewMoreTitle}>
                                                        <AxlChips
                                                        options={{
                                                            chips: '+',
                                                            size: 'small',
                                                            dynamicPage: '',
                                                        }}
                                                        />
                                                    </span>
                                                    )}
                                                </>
                                                );
                                            })()
                                        }
                                    
                                    </div> 
                                    :
                                    options.trialType==='drug' && column==='axl_generic_name'?
                                        financial[column] ? <Link
                                        style={{color:'#000000'}}
                                        target="_blank"
                                        to={{
                                        pathname: `/Generic/${financial['id']}/${financial[column]}/overview`,
                                        }}>
                                            {financial[column]}
                                        </Link>:financial[column]
                                    :
                                    options.trialType==='drug' && column==='axl_generic_class'?
                                        financial[column] ? <Link
                                        style={{color:'#000000'}}
                                        target="_blank"
                                        to={{
                                        pathname: `/GenericClass/${financial['id']}/${financial[column]}/overview`,
                                        }}>
                                            {financial[column]}
                                        </Link>:financial[column]
                                    :
                                    options.trialType==='exits' && column==='axl_deal_date'?
                                        removeDayAndMonthFromDate(financial[column])
                                    :
                                    options.trialType==='exits' && column==='total_raised'?
                                        amountToAbbreviateNumber(financial[column])
                                    :
                                    options.trialType==='exits' && column==='total_enterprise_value'?
                                        <span title={'$'+numberWithCommas(financial[column])}>{financial[column] ? amountToAbbreviateNumber(financial[column]):'-'}</span>
                                    :
                                    options.trialType==='exits' && column==='axl_company_vc'?
                                        financial['axl_target_id'] ? <Link
                                        style={{color:'#000000'}}
                                        target="_blank"
                                        to={{
                                        pathname: `/Company/${financial['axl_target_id']}/${financial['target']}/overview`,
                                        }}>
                                            {financial[column]}
                                        </Link>:financial[column]
                                    :
                                    options.trialType === 'investments' && (column==='raised' || column==='total_raised')?
                                        <span title={'$'+numberWithCommas(financial[column])}>{financial[column] ? amountToAbbreviateNumber(financial[column]):'-'}</span>:
                                    options.trialType === 'investments' && column==='investors'?
                                    <div className="chips-container">
                                        {
                                            (() => {
                                                let counter = 0;
                                            
                                                // Filter valid entries before processing
                                                const renderedColumns = financial['investors'] &&financial['investors'].split(';').map((sub_column) => {
                                                    counter++;
                                                    if (counter < 3) {
                                                    return (
                                                        <span
                                                        title={sub_column}
                                                        key={sub_column}
                                                        >
                                                        <AxlChips
                                                            options={{
                                                            chips: sub_column,
                                                            size: 'small',
                                                            dynamicPage: '',
                                                            }}
                                                        />
                                                        </span>
                                                    );
                                                    }
                                                    return null;
                                                });
                                            
                                                // Generate the tooltip for "view more"
                                                const viewMoreTitle = financial['investors'] && financial['investors'].split(';').map((sub_column) => {
                                                    return (
                                                        (sub_column ? '> '+sub_column : '')
                                                    );
                                                })
                                                .join('\n'); // Join valid entries with newlines
                                            
                                                return (
                                                <>
                                                    {renderedColumns}
                                                    {counter > 2 && (
                                                    <span title={viewMoreTitle}>
                                                        <AxlChips
                                                        options={{
                                                            chips: '+',
                                                            size: 'small',
                                                            dynamicPage: '',
                                                        }}
                                                        />
                                                    </span>
                                                    )}
                                                </>
                                                );
                                            })()
                                        }
                                    
                                    </div>   
                                    :
                                    // <span title={splitText(financial[column],';',1000)}>{financial[column] ? splitText(financial[column],';',10):'-'}</span>:
                                    options.trialType === 'investments' && column==='axl_company_vc'?
                                    <>
                                        {financial[column]}
                                        {financial['axl_company_linkedin'] &&
                                            <Link
                                            class="ms-2"
                                            style={{color:'#6b6767'}}
                                            target="_blank"
                                            title="Linkedin"
                                            to={{
                                            pathname: financial['axl_company_linkedin'],
                                            }}>
                                                <img src={linkedin} width="20px" />
                                            </Link>
                                        }
                                        {financial['axl_company_url'] &&
                                            <Link
                                            class="ms-2"
                                            style={{color:'#6b6767'}}
                                            target="_blank"
                                            title="Website"
                                            to={{
                                            pathname: financial['axl_company_url'],
                                            }}>
                                                <LanguageIcon />
                                            </Link>
                                        }
                                        {financial['axl_company_vc_pr'] &&
                                            <Link
                                            class="ms-2"
                                            style={{color:'#6b6767'}}
                                            target="_blank"
                                            title="Press Release"
                                            to={{
                                            pathname: financial['axl_company_vc_pr'],
                                            }}>
                                                <i class='fas fa-external-link-alt'></i>
                                            </Link>
                                        }
                                    </>
                                    :
                                    options.trialType === 'investments' && column==='round'?
                                        removeDayFromDate(financial['year'])
                                    :
                                    options.trialType === 'trials' && column==='axl_brief_title'?
                                        <p class="description" title={financial[column]}>
                                            {financial[column]}
                                        </p>
                                    :
                                    options.trialType === 'physicians' && column==='title_display'?
                                        <p class="description" title={financial[column]}>
                                            {financial[column]}
                                        </p>
                                    :
                                    column.toLowerCase().includes("description")?
                                        <p class="description" title={financial[column]}>
                                            {financial[column]}
                                        </p>
                                    :
                                    // options.trialType === 'investments' && column==='axl_company_description_extract_from_pressrelease'?
                                    //     <p style={{whiteSpace:'normal'}} title={financial['axl_company_description_extract_from_pressrelease']}>
                                    //         {financial['axl_company_description_extract_from_pressrelease'] && financial['axl_company_description_extract_from_pressrelease'].length > 50?financial['axl_company_description_extract_from_pressrelease'].substring(0,50)+'...':financial['axl_company_description_extract_from_pressrelease']}
                                    //     </p>
                                    // :
                                    options.trialType === 'deals' && column==='type_of_deal'?'':
                                    options.trialType === 'brands' && column==='axl_generic'?'':
                                    options.trialType === 'competitors' && column==='generic'?'':
                                    options.trialType === 'competitors' && column==='axl_generic_class'?'':
                                    options.trialType === 'competitors' && column==='indication'?'':
                                    options.trialType === 'brands' && column==='axl_brand_RoA'?'':
                                    options.trialType === 'competitors' && column==='decision_date'?
                                        removeDayAndMonthFromDate(financial[column])
                                    :
                                    options.trialType === 'competitors' && column==='year'?
                                        removeDayAndMonthFromDate(financial[column])
                                    :
                                    options.trialType === 'competitors' && column==='product_name'?
                                    <>
                                    <div className="chips-container">
                                        {financial[column]}
                                        {financial['openfda_db'] == 'ob' &&
                                            <span title="Orange Book">
                                                    <AxlChips options={{chips: financial['openfda_db'], size: 'small', dynamicPage: 'competitors'}} />
                                            </span>}
                                        {financial['openfda_db'] == 'pb' &&
                                            <span title="Purple Book">
                                                    <AxlChips options={{chips: financial['openfda_db'], size: 'small', dynamicPage: 'competitors'}} />
                                            </span>}
                                        {financial['axl_fda_url']&&<Link
                                            target="_blank"
                                            to={{
                                            pathname: financial['axl_fda_url'],
                                            }}>
                                            <img src={fda_logo} width="40px" />
                                        </Link>}
                                        {financial['stage'] && (financial['stage'].toLowerCase() == 'discn' || financial['stage'].toLowerCase() == 'disc' || financial['stage'].toLowerCase() == 'disc*') &&
                                            <span title={financial['stage']}>
                                                <AxlDot color='red' />
                                            </span>}
                                        {financial['stage'] && (financial['stage'].toLowerCase() !== 'discn' && financial['stage'].toLowerCase() !== 'disc' && financial['stage'].toLowerCase() !== 'disc*') &&
                                            <span title={financial['stage']}>
                                                <AxlDot color='green' />
                                            </span>}
                                        
                                    </div>
                                    <div className="chips-container">
                                        <span>
                                            <AxlChips options={{chips: financial['axl_intervention_category'], size: 'small', dynamicPage: ''}} />
                                        </span>
                                        {
                                            (() => {
                                                let counter = 0;
                                            
                                                // Filter valid entries before processing
                                                const renderedColumns = financial['generic'] &&financial['generic'].split(';').map((sub_column) => {
                                                    counter++;
                                                    if (counter < 3) {
                                                    return (
                                                        <span
                                                        title={sub_column}
                                                        key={sub_column}
                                                        >
                                                        <AxlChips
                                                            options={{
                                                            chips: sub_column,
                                                            size: 'small',
                                                            dynamicPage: '',
                                                            }}
                                                        />
                                                        </span>
                                                    );
                                                    }
                                                    return null;
                                                });
                                            
                                                // Generate the tooltip for "view more"
                                                const viewMoreTitle = financial['generic'] && financial['generic'].split(';').map((sub_column) => {
                                                    return (
                                                        (sub_column ? '> '+sub_column : '')
                                                    );
                                                })
                                                .join('\n'); // Join valid entries with newlines
                                            
                                                return (
                                                <>
                                                    {renderedColumns}
                                                    {counter > 2 && (
                                                    <span title={viewMoreTitle}>
                                                        <AxlChips
                                                        options={{
                                                            chips: '+',
                                                            size: 'small',
                                                            dynamicPage: '',
                                                        }}
                                                        />
                                                    </span>
                                                    )}
                                                </>
                                                );
                                            })()
                                        }
                                    
                                    </div>
                                    </>
                                    :
                                    options.trialType === 'competitors' && column==='trade_name'?
                                    <>
                                    <div className="chips-container">
                                        {financial[column]}
                                        {financial['openfda_db'] == 'pma' &&
                                            <span title="Pre-Market Approval">
                                                    <AxlChips options={{chips: financial['openfda_db'], size: 'small', dynamicPage: 'competitors'}} />
                                            </span>}
                                        {/* {financial['openfda_db'] == 'pb' &&
                                            <span title="Purple Book">
                                                    <AxlChips options={{chips: financial['openfda_db'], size: 'small', dynamicPage: 'competitors'}} />
                                            </span>} */}
                                        {/* {financial['axl_fda_url']&&<Link
                                            target="_blank"
                                            to={{
                                            pathname: financial['axl_fda_url'],
                                            }}>
                                            <img src={fda_logo} width="40px" />
                                        </Link>} */}
                                        {/* {financial['stage'] && (financial['stage'].toLowerCase() == 'discn' || financial['stage'].toLowerCase() == 'disc' || financial['stage'].toLowerCase() == 'disc*') &&
                                            <span title={financial['stage']}>
                                                <AxlDot color='red' />
                                            </span>}
                                        {financial['stage'] && (financial['stage'].toLowerCase() !== 'discn' && financial['stage'].toLowerCase() !== 'disc' && financial['stage'].toLowerCase() !== 'disc*') &&
                                            <span title={financial['stage']}>
                                                <AxlDot color='green' />
                                            </span>} */}
                                        
                                    </div>
                                    </>
                                    :
                                    options.trialType === 'competitors' && column==='stage'?
                                    <>
                                        {financial[column]?financial[column].toUpperCase():'-'}
                                    </>
                                    :
                                    options.trialType === 'venture_capital_people' && column==='education'?
                                    <div className="chips-container">
                                        {
                                            (() => {
                                                let counter = 0;
                                            
                                                // Filter valid entries before processing
                                                const renderedColumns = financial['education'] &&financial['education'].split(';').map((sub_column) => {
                                                    counter++;
                                                    if (counter < 3) {
                                                    return (
                                                        <span
                                                        title={sub_column}
                                                        key={sub_column}
                                                        >
                                                        <AxlChips
                                                            options={{
                                                            chips: sub_column,
                                                            size: 'small',
                                                            dynamicPage: '',
                                                            }}
                                                        />
                                                        </span>
                                                    );
                                                    }
                                                    return null;
                                                });
                                            
                                                // Generate the tooltip for "view more"
                                                const viewMoreTitle = financial['education'] && financial['education'].split(';').map((sub_column) => {
                                                    return (
                                                        (sub_column ? '> '+sub_column : '')
                                                    );
                                                })
                                                .join('\n'); // Join valid entries with newlines
                                            
                                                return (
                                                <>
                                                    {renderedColumns}
                                                    {counter > 2 && (
                                                    <span title={viewMoreTitle}>
                                                        <AxlChips
                                                        options={{
                                                            chips: '+',
                                                            size: 'small',
                                                            dynamicPage: '',
                                                        }}
                                                        />
                                                    </span>
                                                    )}
                                                </>
                                                );
                                            })()
                                        }
                                    
                                    </div>
                                    :
                                    options.trialType === 'venture_capital' && column==='id'?
                                        <div className="chips-container">
                                            {
                                                (() => {
                                                    let counter = 0;
                                                  
                                                    // Filter valid entries before processing
                                                    const renderedColumns = university
                                                      .filter((sub_column) => financial[sub_column['university']])
                                                      .map((sub_column) => {
                                                        counter++;
                                                        if (counter < 3) {
                                                          return (
                                                            <span
                                                              title={financial[sub_column['degree']]}
                                                              key={sub_column['university']}
                                                            >
                                                              <AxlChips
                                                                options={{
                                                                  chips: financial[sub_column['university']],
                                                                  size: 'small',
                                                                  dynamicPage: '',
                                                                }}
                                                              />
                                                            </span>
                                                          );
                                                        }
                                                        return null;
                                                      });
                                                  
                                                    // Generate the tooltip for "view more"
                                                    const viewMoreTitle = university
                                                      .filter((sub_column) => financial[sub_column['university']])
                                                      .map((sub_column) => {
                                                        return (
                                                            (financial[sub_column['university']] ? `University: ${financial[sub_column['university']]} ` : '') +
                                                            (financial[sub_column['degree']] ? `| Degree: ${financial[sub_column['degree']]}` : '')
                                                          );
                                                      })
                                                      .join('\n'); // Join valid entries with newlines
                                                  
                                                    return (
                                                      <>
                                                        {renderedColumns}
                                                        {counter > 2 && (
                                                          <span title={viewMoreTitle}>
                                                            <AxlChips
                                                              options={{
                                                                chips: '+',
                                                                size: 'small',
                                                                dynamicPage: '',
                                                              }}
                                                            />
                                                          </span>
                                                        )}
                                                      </>
                                                    );
                                                  })()
                                            }
                                        
                                        </div>
                                    :
                                    options.trialType === 'mocks_transcript' && column==='Company'?
                                        <>
                                            {financial[column]}
                                            {
                                                column==='Company' && <><br></br>
                                                <small title={financial['Title']}  class="small-70">
                                                    {financial['Title'].length>3?financial['Title'].toLowerCase():financial['Title'].toUpperCase()}
                                                </small></>
                                            }
                                            {
                                                column==='Company' && financial['Speaker'] && <> | <small title={financial['Speaker']} class="small-70">{financial['Speaker']}</small></>
                                            }
                                        </>
                                    :
                                    options.trialType === 'mocks_transcript' && column==='Product_Name'?
                                        <>
                                            {financial[column]}
                                            <br></br>
                                            {
                                                column==='Product_Name' && financial['Trial_Name'] &&<><small title={'Trial Name:- '+financial['Trial_Name']}  class="small-70 text-uppercase">{financial['Trial_Name']}</small></>
                                            }
                                            {financial['Trial_Name'] && financial['Condition']?' | ':''}
                                            {
                                                column==='Product_Name' && financial['Condition'] && <> <small title={'Condition:- '+financial['Condition']} class="small-70">{financial['Condition']}</small></>
                                            }
                                        </>
                                    :
                                    options.trialType === 'mocks_transcript' && column==='Topic'?
                                        <>
                                            {
                                                column==='Topic' && financial['Topic'] &&<><small title={'Topic:- '+financial['Topic']} class="small-70 text-uppercase">{financial['Topic']}</small></>
                                            }
                                            {financial['Topic'] && financial['Subtopic']?' | ':''}
                                            {
                                                column==='Topic' && financial['Subtopic'] && <> <small title={'Subtopic:- '+financial['Subtopic']} class="small-70">{financial['Subtopic']}</small></>
                                            }
                                        </>
                                    :
                                    options.trialType === 'mocks_transcript' && column==='Sentiment'?
                                        <>
                                            {
                                                column==='Sentiment' && <> <small title={financial['Sentiment']} class="small-70">
                                                    <Brightness1Icon
                                                        style={{color: 
                                                            financial['Sentiment']=='negative'
                                                            ?'#FFAEAE':
                                                            financial['Sentiment']=='positive'
                                                            ?'#9fe896'
                                                            :'#6b6767'}}
                                                    />
                                                </small></>
                                            }
                                            {financial['Actual_Expected_or_Hoping'] && financial['Condition']?' | ':''}
                                            {
                                                column==='Sentiment' && financial['Actual_Expected_or_Hoping'] &&<><small class="small-70 text-uppercase">{financial['Actual_Expected_or_Hoping']}</small></>
                                            }
                                            
                                        </>
                                    :
                                    options.trialType === 'venture_capital_people' && column==='activities'?'':
                                    column==='axl_brand_name'?
                                        <>
                                            {financial[column]}
                                            {financial['axl_fda_url']&&<Link
                                            target="_blank"
                                            to={{
                                            pathname: financial['axl_fda_url'],
                                            }}>
                                                <img class="ms-2" src={fda_logo} width="45px" />
                                            </Link>}
                                        </>
                                    :
                                    column==='All_Investors' && options.trialType === 'mocks_investments'?
                                    <span title={splitText(financial[column],';',10)}>{splitText(financial[column],';',1)}...</span>:
                                    column==='Company' && options.trialType==='mocks_investments'?
                                        <Link
                                        target="_blank"
                                        to={{
                                        pathname: financial['URL_for_Company'],
                                        }}>
                                            {financial[column]}
                                        </Link>:
                                    column==='axl_vc_name'?
                                        <>
                                        {
                                            financial[column]}
                                        {financial['axl_linkedin_page'] &&
                                            <Link
                                            class="ms-2"
                                            style={{color:'#6b6767'}}
                                            target="_blank"
                                            title="Linkedin"
                                            to={{
                                              pathname: financial['axl_linkedin_page'],
                                            }}>
                                              <img src={linkedin} width="20px" />
                                            </Link>}
                                            {financial['axl_bio_page'] &&
                                                <Link
                                                class="ms-2"
                                                target="_blank"
                                                title="Website"
                                                style={{color:'#6b6767'}}
                                                to={{
                                                pathname: financial['axl_bio_page'],
                                                }}>
                                                <LanguageIcon/>
                                                </Link>
                                            }
                                        </>
                                        :
                                        options.trialType==='physicians' && column==='axl_physician_name'?
                                        <>
                                        {
                                            financial[column]}
                                            {financial['doximity'] &&
                                                <Link
                                                class="ms-2"
                                                target="_blank"
                                                title="Doximity"
                                                style={{color:'#6b6767'}}
                                                to={{
                                                pathname: financial['doximity'],
                                                }}>
                                                <img src={doximity} width="20px" />
                                                </Link>
                                            }
                                            {financial['usnews'] &&
                                                <Link
                                                class="ms-2"
                                                target="_blank"
                                                title="US News"
                                                style={{color:'#6b6767'}}
                                                to={{
                                                pathname: financial['usnews'],
                                                }}>
                                                <img src={usnews} width="20px" />
                                                </Link>
                                            }
                                            {financial['linkedin'] &&
                                                <Link
                                                class="ms-2"
                                                style={{color:'#6b6767'}}
                                                target="_blank"
                                                title="Linkedin"
                                                to={{
                                                pathname: financial['linkedin'],
                                                }}>
                                                <img src={linkedin} width="20px" />
                                                </Link>
                                            }
                                            {financial['website_1'] &&
                                                <Link
                                                class="ms-2"
                                                target="_blank"
                                                title="Website"
                                                style={{color:'#6b6767'}}
                                                to={{
                                                pathname: financial['website_1'],
                                                }}>
                                                <img src={website} width="20px" />
                                                </Link>
                                            }
                                        </>
                                        :
                                    options.trialType==='venture_capital_people'?
                                        <>
                                        <span title={splitText(financial[column],';',10)}>{splitText(financial[column],';',1)}</span>
                                        {column==='name' && financial['linkedin'] &&
                                            <Link
                                            class="ms-2"
                                            style={{color:'#6b6767'}}
                                            target="_blank"
                                            title="Linkedin"
                                            to={{
                                              pathname: financial['linkedin'],
                                            }}>
                                              <img src={linkedin} width="20px" />
                                            </Link>}
                                            {
                                                column==='name' && <><br></br><small class="small-70">{financial['title']}</small></>
                                            }
                                            {column==='name' && financial['title'] && financial['axl_company']?' | ':''}
                                            {
                                                column==='name' && <><small class="small-70">{financial['axl_company']}</small></>
                                            }
                                        </>
                                        :
                                    options.trialType==='deals' && column==='deal_date'?
                                        convertToHumanDate(financial[column])
                                    :
                                    options.trialType==='trials' && column==='axl_start_date'?
                                        <>
                                            <span style={{fontWeight: '400'}}><b>Start:</b> {financial['axl_start_date'] && convertToHumanDate(financial['axl_start_date'])+' '}</span><br></br>
                                            <span style={{fontWeight: '400'}}><b>End:</b> {financial['axl_completion_date'] && convertToHumanDate(financial['axl_completion_date'])+' '}</span>
                                        </>
                                    :
                                    options.trialType==='trials' && column==='axl_trial_length'?
                                    <span title={convertToHumanDate(financial['axl_start_date'])+' to '+convertToHumanDate(financial['axl_primary_completion_date'])}>
                                        {differenceDays(financial['axl_start_date'], financial['axl_primary_completion_date'])}
                                        {financial['axl_primary_completion_date'] && <br/>}
                                        {convertToHumanDate(financial['axl_primary_completion_date'])}
                                    </span>
                                    :
                                    options.trialType==='trials' && column==='Early_/_Delay'?
                                    <span title={'Primary Completion Date: ['+convertToHumanDate(financial['axl_primary_completion_date'])+']\nCurrent Completion Date: ['+convertToHumanDate(financial['completion_date'])+']'}>
                                        {differenceDays(financial['completion_date'], financial['axl_primary_completion_date']).replace('-','')+" "}
                                        {
                                            differenceDays(financial['completion_date'], financial['axl_primary_completion_date']) === '-' || Number(differenceDays(financial['completion_date'], financial['axl_primary_completion_date']).replace(" days","")) === 0 ?
                                                <AxlChips options={{chips: 'No Delay', size: 'small', dynamicPage: ''}} />
                                            :
                                                Number(differenceDays(financial['completion_date'], financial['axl_primary_completion_date']).replace(" days","")) > 0 ? 
                                                    <AxlChips options={{chips: 'Early', size: 'small', dynamicPage: ''}} />
                                                : 
                                                    <AxlChips options={{chips: 'Delay', size: 'small', dynamicPage: ''}} />
                                        }
                                    </span>
                                    :
                                    options.trialType==='trials' && column==='ESM'?
                                    financial['enrollment'] && financial['no_of_location'] && Number(differenceDays(financial['axl_start_date'], financial['axl_primary_completion_date']).replace(" days",""))?
                                    Math.round((Number(financial['enrollment'])/Number(financial['no_of_location'])/Number(differenceDays(financial['axl_start_date'], financial['axl_primary_completion_date']).replace(" days","")))*30 * 100)/100:'-'
                                    :
                                    options.trialType==='trials' && column==='axl_start_date'?
                                        convertToHumanDate(financial[column])
                                    :
                                    options.trialType==='trials' && column==='no_of_location'?
                                        <span title={financial['locations']}>
                                            {financial[column]}
                                        </span>
                                    :
                                    options.trialType==='trials' && column==='nct_number'?
                                        <Link
                                        style={{color:'#000000'}}
                                        target="_blank"
                                        to={{
                                        pathname: `https://clinicaltrials.gov/study/${financial[column]}`,
                                        }}>
                                            {financial[column]}
                                        </Link>
                                    :
                                    options.trialType==='trials' && column==='axl_company'?
                                        <Link
                                        style={{color:'#000000'}}
                                        target="_blank"
                                        to={{
                                        pathname: `/Company/${financial['axl_company_id_id']}/${financial[column]}/overview`,
                                        }}>
                                            {financial[column]}
                                        </Link>
                                    :
                                    options.trialType==='deals' && (column==='target' || column==='acquisitions')?
                                        <Link
                                        style={{color:'#000000'}}
                                        target="_blank"
                                        to={{
                                        pathname: `/Company/${financial['axl_target_id']}/${financial['target']}/overview`,
                                        }}>
                                            {financial[column]}
                                        </Link>
                                    :
                                    options.trialType==='deals' && column==='valuation'?
                                        amountToAbbreviateNumber(financial[column])
                                    :
                                    column==='axl_brand_RoA' || column==='axl_vc_category'?
                                        <span title={splitText(financial[column],';',100)}>{splitText(financial[column],';',100)}</span>
                                    :
                                    options.trialType==='players' && column==='axl_stock_price_usd'?
                                    (
                                        financial[column]>0?'$'+numberWithCommas(financial[column]):'-'
                                    ):
                                    options.trialType==='players' && column==='axl_stock_ticker'?
                                    (
                                        financial[column]?financial[column].toUpperCase():'-'
                                    ):
                                    options.trialType==='players' && column==='axl_country'?
                                    (
                                        financial[column].length>3?financial[column].toLowerCase():financial[column].toUpperCase()
                                    ):(column==='axl_market_cap'?
                                    (
                                        financial[column]>0?'$'+convertToMimillions(financial[column]):'-'
                                    ):financial[column])
                                }
                                {
                                    column==='axl_vc_name' && <><br></br><small class="small-70">{financial['axl_vc_title']} {financial['axl_vc_firm'] && <> | {financial['axl_vc_firm']}</>}</small></>
                                }
                                {
                                    options.trialType==='trials' &&
                                        column==='axl_primary_intervention' && <>
                                        {/* <br></br>
                                        <span style={{fontWeight: '400', fontSize: 'smaller'}}>Start: {financial['axl_start_date'] && convertToHumanDate(financial['axl_start_date'])+' '}</span><br></br>
                                        <span style={{fontWeight: '400', fontSize: 'smaller'}}>End: {financial['axl_completion_date'] && financial['axl_completion_date']+' '}</span> */}
                                        </>
                                }
                                {
                                    options.showChips && 
                                    Object.keys(options.chipColumn).map((chip_column)=>{
                                        return options.chipColumn[chip_column] === 'company_type'?
                                        column === chip_column && financial[options.chipColumn[chip_column]] &&
                                        <div className="chips-container">
                                            <AxlChips options={{chips: financial[options.chipColumn[chip_column]], size: 'small', dynamicPage: 'VenturnCapital'}} />
                                        </div>
                                        : 
                                        typeof options.chipColumn[chip_column] === "string"? 
                                            chip_column === 'axl_generic'?
                                            column === chip_column && financial[options.chipColumn[chip_column]] &&
                                                <div className="chips-container">
                                                    <AxlChips options={{chips: financial[options.chipColumn[chip_column]], size: 'small', dynamicPage: 'Generic Name'}} />
                                                </div>
                                            :
                                            column === chip_column && financial[options.chipColumn[chip_column]] && 
                                                <div className="chips-container">
                                                    <AxlChips options={{chips: financial[options.chipColumn[chip_column]], size: 'small', dynamicPage: ''}} />
                                                </div>
                                            :
                                            column === chip_column && financial[chip_column] &&
                                            <div className="chips-container">
                                                {options.chipColumn[chip_column].map((value_chip) => {
                                                    // console.log(value_chip,'TEST')
                                                    if (financial[value_chip]) {
                                                        return value_chip === 'nct_number'?
                                                        <AxlChips options={{chips: financial[value_chip], size: 'small', dynamicPage: 'nct number'}} />
                                                        :
                                                        <AxlChips options={{chips: financial[value_chip].replaceAll("_"," "), size: 'small', dynamicPage: '', columnName:value_chip.replaceAll("_"," ").replaceAll("axl","").toUpperCase()}} />;
                                                    }
                                                    return null;
                                                })}
                                            </div>
                                    })
                                }
                                {
                                    column === 'Phase 1' || column === 'Phase 2' || column === 'Phase 3' || column === 'Approved'?
                                    <div className="progress">
                                        <div className="progress-bar" role="progressbar" style={{width: financial[column]}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                    </div>:''
                                }

                        </TableCell>
                        })
                    }
                </TableRow>
            ))}
            </TableBody>
        </Table> 
        {showModal && (
        options.leafModalData ? (
            (options.trialType === 'trials' || options.trialType === 'phases' || options.trialType === 'competitors') && options.leafModalData['name']? (<TrialsModal item={options.leafModalData} onClose={() => setShowModal(false)} />) :
            options.trialType === 'venture_capital_people' && options.leafModalData['name']? (<PeopleCEOModal item={options.leafModalData} onClose={() => setShowModal(false)} />) :
            options.trialType === 'mocks_transcript' && options.leafModalData['name']? (<TranscriptModal item={options.leafModalData} onClose={() => setShowModal(false)} />) :
            options.trialType === 'investments' && options.leafModalData['name']? (<VentureCapitalCompanyDealsModal item={options.leafModalData} onClose={() => setShowModal(false)} />) :
            (options.trialType === 'deals' || options.trialType === 'exits') && options.leafModalData['name']? (<CompanyDealsModal item={options.leafModalData} onClose={() => setShowModal(false)} />) :
            options.trialType === 'venture_capital' && options.leafModalData['name']? (<VentureCapitalModal item={options.leafModalData} onClose={() => setShowModal(false)} />) :
            options.trialType === 'players' ? (<PlayersModal item={options.leafModalData} onClose={() => setShowModal(false)} />) : options.trialType === 'brands' || options.trialType === 'mocks' ? (<BrandModal item={options.leafModalData} onClose={() => setShowModal(false)} />) : null ) : null
        )}
        </>
    );
};

export default GeneralTable;
