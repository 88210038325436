import React from 'react';
import { Modal, Backdrop, Fade, Card, CardHeader, CardContent } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { lightGreen } from '@material-ui/core/colors';
import { useParams } from 'react-router-dom';
import { convertToHumanDate } from './Helper';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  card: {
    width: '80%',
    maxWidth: '70%',
    backgroundColor: '#fff',
    overflowY: 'auto',
    maxHeight: '80%',
    position: 'relative',
    boxShadow: 'none',
  },
  header: {
    textAlign: 'center',
    background: '#2bb670',
    fontWeight: 'bold',
    padding: '10px 0',
    position: 'sticky',
    top: 0,
    '& span': {
      color: '#fff !important',
      fontSize: '2rem',
      fontWeight: 'bold',
      textTransform: 'capitalize'
    }
  },
  cards: {
    marginTop: 20,
    padding: 0,
  },
  cardsHeader: {
    display: 'flex',
    background: '#c5ecda',
    fontWeight: 'bold',
    padding: '10px 10px',
    '& h3' : {
      fontSize: 20,
      fontWeight: 'bold',
      margin: 0,
    },
    '& p' : {
      fontSize: 18,
      margin: 0,
      '& b': {
        fontWeight: 'bold',
      },
    },
  },
  cardConditionPhaseStatus: {
    fontSize: '14px !important',
    paddingTop: 5,
  },
  leftCard: {
    width: '70%',
  },
  rightCard: {
    width: '30%',
    textAlign: 'right',
  },
  cardsBody: {
    display: 'flex',
    padding: 0,
    '& p' : {
      fontSize: 16,
      margin: 0,
      '& b': {
        fontWeight: 'bold',
      },
    },
  },
  cardsBodyLeft: {
    width: '40%',
    padding: 10,
    '& p' : {
      fontSize: 18,
    },
  },
  cardsBodyRight: {
    width: '60%',
    padding: 10,
    borderLeft: '1px solid #ccc',
    paddingLeft: 10,
  },
  closeBtn: {
    position: 'fixed',
    top: 0,
    right: 0,
    background: '#c5ecda',
    border: 'none',
    fontSize: 20,
    fontWeight: 'bold',
    color: '#387249',
    padding: '11px 20px',
    cursor: 'pointer',
  },
}));

function TrialsModal({ item, onClose }) {
  const { diseaseName } = useParams();
  const classes = useStyles();
  const handleClose = () => {
    onClose();
  };
  return (
    {item} &&
      <Modal
        className={classes.modal}
        open={true}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={true}>
          <Card className={classes.card}>
            <CardHeader
              className={classes.header}
              title={item['name']}
            />
            <button className={classes.closeBtn} onClick={handleClose}>x</button>
            <CardContent>
              {item['nextData'].map((card) => (
                <Card className={classes.cards} key={card}>
                  <CardContent style={{padding:0}}>
                    <div className={classes.cardsHeader}>
                      <div className={classes.leftCard}>
                        <h3>{card.axl_brief_title}</h3>
                        <p className={classes.cardConditionPhaseStatus}>{diseaseName?diseaseName:card.condition_name}{card.axl_phase?' | '+card.axl_phase:''}{card.axl_status?' | '+card.axl_status:''}</p>
                      </div>
                      <div className={classes.rightCard}>
                        <p> </p>
                        <p>{card.axl_company}</p>
                      </div>
                    </div>
                    <div className={classes.cardsBody}>
                      <div className={classes.cardsBodyLeft}>
                        {card.start_date?card.start_date:card.axl_start_date &&
                            <p><b>Started:</b> {card.start_date?convertToHumanDate(card.start_date):convertToHumanDate(card.axl_start_date)}</p>
                        }
                        {card.axl_completion_date &&
                            <p><b>Ends:</b> {convertToHumanDate(card.axl_completion_date)}</p>
                        }
                        {card.axl_comparison_intervention &&
                            <p><b>Compared to:</b> {card.axl_comparison_intervention.replace(/,/g, ', ')}</p>
                        }
                        {card.enrollment &&
                            <p><b>Participants:</b> {card.enrollment}</p>
                        }
                        {card.axl_po_measure_1 &&
                            <p><b>Primary Outcome Measures:</b> {card.axl_po_measure_1}</p>
                        }
                        {card.axl_timeframe &&
                            <p><b>Time Frame:</b> {card.axl_timeframe} </p>
                        }
                      </div>
                      <div className={classes.cardsBodyRight}>
                        {card.axl_official_title &&
                          <>
                            <p><b>Introduction:</b></p>
                            <p>{card.axl_official_title}</p>
                          </>
                        }
                        {card.axl_brief_summary &&
                          <>
                            <p><b>Description:</b></p>
                            <p>{card.axl_brief_summary}</p>
                          </>
                        }
                        {card.axl_moa &&
                          <>
                            <p><b>Rationale:</b></p>
                            <p>{card.axl_moa}</p>
                          </>
                        }
                        <br/>
                        {card.nct_number ? 
                          <a href={`https://clinicaltrials.gov/study/`+card.nct_number} target="_blank" rel="noopener noreferrer">Read More [{card.nct_number}]..</a>
                          : null
                        }
                      </div>
                    </div>
                  </CardContent>
                </Card>
              ))}
            </CardContent>
          </Card>
        </Fade>
      </Modal>
  );
};

export default TrialsModal;