import React from 'react';
import { Chip, Avatar } from '@material-ui/core';
import './AxlChips.scss';
import { Link } from 'react-router-dom';

const AxlChips = ({ options }) => {
    const { chips, color, size, variant, dynamicPage='', columnName='', } = options;
    const itemsToRemove = ['other', 'others'];
    let splitChips = '';
    if(typeof chips == 'object'){
        splitChips = chips;
    }else{
        if(chips){
            splitChips = chips.includes(';') || chips.includes('|') 
            ? chips.split(/;|\|/).map(chip => chip.trim()).filter(chip => !itemsToRemove.includes(chip.toLowerCase())) 
            : [chips.trim()];
        }else{
            splitChips = []
        }

    }

    const backgroundColorMap = {
        'devices': '#FFEBB7',         // Light pastel yellow
        'diagnostics': '#FFD9A6',     // Pastel orange
        'diagnostic': '#FFD9A6',     // Pastel orange
        'drugs': '#FFABAB',           // Soft coral red
        'vaccine': '#FFABAB',         // Soft coral red
        'vaccines': '#FFABAB',        // Soft coral red
        'biologics': '#FFABAB',       // Soft coral red
        'biotech': '#FFAEAE',         // Light pastel red
        'digital health': '#FFEBB7',  // Pastel pink
        'healthcare services': '#FFB7B2',  // Light salmon pink
        'healthcare it': '#E1BEE7',   // Soft purple
        'healthcare': '#E1BEE7',      // Soft purple
        'pharma': '#FFAEAE',          // Light pastel red
        'biotechnology': '#FFAEAE',   // Light pastel red
        'medtech': '#FFEBB7',         // Light pastel yellow
        'health delivery': '#FFB7B2', // Light pastel salmon pink
        'biomanufacturing': '#FFAEAE',// Light pastel red
        'bio-it': '#F8BBD0',          // Pastel pink
        'health-it': '#E1BEE7',       // Soft pastel purple
        'health services': '#FFB7B2', // Light pastel salmon pink
        'global health': '#FFABAB',   // Soft coral red
        'molecular diagnostics': '#FFD9A6',  // Pastel orange
        'foodtech': '#FFABAB',        // Soft coral red
        'agtech': '#FFABAB',          // Soft coral red
        'healthtech': '#F8BBD0',      // Pastel pink
        'success': '#D7F5D3'  ,       // Light pastel green
        'error': '#FFABAB',
        'early': '#D7F5D3'  ,       // Light pastel green
        'delay': '#FFABAB',           // Soft coral red
        'no delay': '#D7F5D3'  ,       // Light pastel green
        'drug': '#D7F5D3',            // Light pastel green
        // dark blue
        'device': '#BBDEFB',          // Light pastel blue
        'diagnostic test': '#F8BBD0', // Pastel pink
        // drug delivery turquoise blue
        'drug delivery': '#B2EBF2',  // Light pastel turquoise blue
        'founded': '#F8BBD0', // Pastel pink
        // 'neur': '#f1c54b', // Pastel dark yellow
        // 'alle': '#93f493', // Pastel dark green
        // 'pulm': '#93f493', // Pastel dark green
        // 'card': '#add8e6', // Pastel dark blue
        // 'hema': '#ffc0cb', // Pastel dark pink
        // 'endo': '#f3f371', // Pastel light yellow
        // 'opht': '#ffa07a', // Pastel dark pink
        'completed': '#D7F5D3',            // Light pastel green
        'terminated': '#FFABAB',           // Soft coral red
        'suspended': '#FFABAB',           // Soft coral red
        'withdrawn': '#FFABAB',
        'recruiting': '#D7F5D3',  // Light pastel green
        'not yet recruiting': '#FFD9A6',  // Pastel orange
        '+': '#93f493', // Pastel dark green
        'ob': '#FFD9A6',  // Pastel orange
        'pb': '#E1BEE7',  // Pastel orange
        'pma': '#add8e6',  // Pastel blue
        'procedure' : '#E1BEE7',
        'enrolling by invitation': '#D7F5D3',

        'nutrition': '#8dae00',
        'rheumatology': '#4646e5',
        'radiology': '#708090',
        'physical medicine rehabilitation': '#009e9e',
        'sports medicine': '#009e9e',
        'emergency medicine': '#ff0000',
        'occupational medicine': '#8b4513',
        'oral maxillofacial surgery': '#ff7f50',
        'sleep medicine': '#00008b',
        'radiology interventional': '#454545',
        'pharmacology': '#006400',
        'toxicology': '#006400',
        'hematology': '#8b0000',
        'neuromuskuloskeletal medicine': '#006400',
        'addiction abuse medicine': '#4c0099',
        'cardiology': '#8b0000',
        'cardiology interventional': '#8b0000',
        'cardiothoracic vascular surgery': '#8b0000',
        'electrophysiology': '#00ffff',
        'transplant surgery': '#2e8b57',
        'anesthesia pain medicine': '#808080',
        'neurology': '#808080',
        'family medicine': '#008000',
        'psychiatry mental health': '#008000',
        'primary care': '#225e22',
        'neuropsychology neuropsychiatry': '#4b0082',
        'transplant': '#4cbb17',
        'hospice palliative care': '#e6e6fa',
        'immunology': '#add8e6',
        'otolaryngology ent': '#add8e6',
        'pediatrics': '#add8e6',
        'neonatology': '#add8e6',
        'allergy immunology': '#add8e6',
        'pulmonology': '#add8e6',
        'dermatology': '#c4a484',
        'obstetrics gynecology': '#ffb6c1',
        'womens health': '#ffb6c1',
        'speech therapy': '#e6e6fa',
        'pathology': '#800000',
        'genetics': '#000080',
        'internal medicine': '#000080',
        'undersea hyperbaric medicine': '#0077be',
        'critical care medicine': '#ffa500',
        'hematology oncology': '#ffa500',
        'oncology': '#ffa500',
        'radiation oncology': '#ffa500',
        'plastic reconstructive surgery': '#eae0c8',
        'dentistry': '#f0f0f0',
        'ophthalmology': '#ffc0cb',
        'optometry': '#ffc0cb',
        'gastroenterology': '#800080',
        'infectious diseases': '#ff0000',
        'hepatology': '#582a12',
        'hospitalist': '#4169e1',
        'geriatrics': '#808000',
        'neurosurgery': '#c0c0c0',
        'general surgery': '#4682b4',
        'surgery': '#4682b4',
        'podiatry': '#d2b48c',
        'endocrinology': '#008080',
        'obesity medicine': '#008080',
        'chiropractic medicine': '#ffffff',
        'orthopedics': '#ffffff',
        'musculoskeletal': '#ffffff',
        'nursing physician assistant': '#ffffff',
        'nephrology': '#ffff00',
        'urology': '#ffff00'
    };
    
    // Color maps for text, using dark text for light backgrounds and white for darker shades
    const colorMap = {
        'devices': 'black',
        'diagnostics': 'black',
        'drugs': 'white',
        'vaccine': 'white',
        'vaccines': 'white',
        'biologics': 'white',
        'biotech': 'white',
        'digital health': 'black',
        'healthcare services': 'white',
        'healthcare it': 'black',
        'healthcare': 'black',
        'pharma': 'white',
        'biotechnology': 'white',
        'medtech': 'black',
        'health delivery': 'black',
        'biomanufacturing': 'white',
        'bio-it': 'black',
        'health-it': 'black',
        'health services': 'black',
        'global health': 'white',
        'molecular diagnostics': 'black',
        'foodtech': 'white',
        'agtech': 'white',
        'healthtech': 'black',

        'nutrition': '#ffffff',
        'rheumatology': '#ffffff',
        'radiology': '#ffffff',
        'physical medicine rehabilitation': '#ffffff',
        'sports medicine': '#ffffff',
        'emergency medicine': '#000000',
        'occupational medicine': '#ffffff',
        'oral maxillofacial surgery': '#000000',
        'sleep medicine': '#ffffff',
        'radiology interventional': '#ffffff',
        'pharmacology': '#ffffff',
        'toxicology': '#ffffff',
        'hematology': '#ffffff',
        'neuromuskuloskeletal medicine': '#ffffff',
        'addiction abuse medicine': '#ffffff',
        'cardiology': '#ffffff',
        'cardiology interventional': '#ffffff',
        'cardiothoracic vascular surgery': '#ffffff',
        'electrophysiology': '#000000',
        'transplant surgery': '#ffffff',
        'anesthesia pain medicine': '#ffffff',
        'neurology': '#ffffff',
        'family medicine': '#ffffff',
        'psychiatry mental health': '#ffffff',
        'primary care': '#ffffff',
        'neuropsychology neuropsychiatry': '#ffffff',
        'transplant': '#ffffff',
        'hospice palliative care': '#000000',
        'immunology': '#000000',
        'otolaryngology ent': '#000000',
        'pediatrics': '#000000',
        'neonatology': '#000000',
        'allergy immunology': '#000000',
        'pulmonology': '#000000',
        'dermatology': '#ffffff',
        'obstetrics gynecology': '#000000',
        'womens health': '#000000',
        'speech therapy': '#000000',
        'pathology': '#ffffff',
        'genetics': '#ffffff',
        'internal medicine': '#ffffff',
        'undersea hyperbaric medicine': '#ffffff',
        'critical care medicine': '#000000',
        'hematology oncology': '#000000',
        'oncology': '#000000',
        'radiation oncology': '#000000',
        'plastic reconstructive surgery': '#000000',
        'dentistry': '#000000',
        'ophthalmology': '#000000',
        'optometry': '#000000',
        'gastroenterology': '#ffffff',
        'infectious diseases': '#000000',
        'hepatology': '#ffffff',
        'hospitalist': '#ffffff',
        'geriatrics': '#ffffff',
        'neurosurgery': '#ffffff',
        'general surgery': '#ffffff',
        'surgery': '#ffffff',
        'podiatry': '#ffffff',
        'endocrinology': '#ffffff',
        'obesity medicine': '#ffffff',
        'chiropractic medicine': '#000000',
        'orthopedics': '#000000',
        'musculoskeletal': '#000000',
        'nursing physician assistant': '#000000',
        'nephrology': '#000000',
        'urology': '#000000'
    };
        
    let chipsArray = []
    // Map the chips to corresponding styles
    if(dynamicPage == 'VenturnCapital'){
        chipsArray = splitChips.map(chip => {
            const lowerCaseChip = chip.axl_company_type?chip.axl_company_type.toLowerCase():''; // Make the chip lowercase
            return {
                key: chip.axl_company_type,
                label: chip.axl_company_type,
                avatar: chip.count,
                color: variant === 'outlined' ? color : colorMap[lowerCaseChip] || 'black', // Default to black if no match
                backgroundColor: variant === 'outlined' ? 'white' : backgroundColorMap[lowerCaseChip] || '#ddd', // Default to grey if no match
            };
        });
    }else{
        chipsArray = splitChips.map(chip => {
            const lowerCaseChip = chip.toLowerCase(); // Make the chip lowercase
            return {
                key: chip,
                label: chip,
                avatar: '',
                color: variant === 'outlined' ? color : colorMap[lowerCaseChip] || 'black', // Default to black if no match
                backgroundColor: variant === 'outlined' ? 'white' : backgroundColorMap[lowerCaseChip] || '#ddd', // Default to grey if no match
            };
        });
    }

    return (
        <>
            {chipsArray.map((chip, index) => (
                dynamicPage == 'Generic Name'?
                <Link target="_blank" to={`/${dynamicPage}/0/${chip.label}/overview`}
                >
                    <Chip
                    key={index}
                    title={columnName?columnName+':- '+chip.label:chip.label}
                    label={chip.label.toLowerCase()}
                    className='axl-chip'
                    // avatar={<Avatar>22</Avatar>}
                    variant={variant ? variant : 'default'}
                    size={size}
                    style={{ color: chip.color, backgroundColor: chip.backgroundColor }}
                    />
                </Link>:

                dynamicPage == 'nct number'?
                <Link target="_blank" to={{
                    pathname: `https://clinicaltrials.gov/study/`+chip.label
                    }}
                >
                    <Chip
                    key={index}
                    title={columnName?columnName+':- '+chip.label:chip.label}
                    label={chip.label}
                    className='axl-chip'
                    // avatar={<Avatar>22</Avatar>}
                    variant={variant ? variant : 'default'}
                    size={size}
                    style={{ color: chip.color, backgroundColor: '#BBDEFB' }}
                    />
                </Link>:

                dynamicPage == 'short_chips'?
                    <Chip
                    key={index}
                    title={columnName?columnName+':- '+chip.label:chip.label}
                    label={chip.label.substring(0,4)}
                    className='axl-chip'
                    // avatar={<Avatar>22</Avatar>}
                    variant={variant ? variant : 'default'}
                    size={size}
                    style={{ color: chip.color, backgroundColor: chip.backgroundColor }}
                    />:

                dynamicPage == 'competitors'?
                    <Chip
                    key={index}
                    label={chip.label.toUpperCase()}
                    className='axl-chip'
                    // avatar={<Avatar>22</Avatar>}
                    variant={variant ? variant : 'default'}
                    size={size}
                    style={{ color: chip.color, backgroundColor: chip.backgroundColor }}
                    />:

                <Chip
                    key={index}
                    // title={columnName?columnName+':- '+chip.label:chip.label}
                    label={chip.label.length>3?chip.label.toLowerCase():chip.label}
                    className='axl-chip'
                    avatar={chip.avatar&&<Avatar>{chip.avatar}</Avatar>}
                    variant={variant ? variant : 'default'}
                    size={size}
                    style={{ color: chip.color, backgroundColor: chip.backgroundColor }}
                />
            ))}
        </>
    );
};

export default AxlChips;
